import { useMutation } from '@tanstack/react-query';
import { IErrorResponse, IOrderForm, order } from 'common/api';
import { EnumSnackType, useSnacks } from 'shared/hooks/useSnacks';
import { AxiosError } from 'axios';
import { useTranslation } from 'next-i18next';
import { UseMutationResult } from '@tanstack/react-query/src/types';
import { logger } from '@indriver/elastic-logger';
import { useUTMRouter } from 'shared/hooks';
import { sendAnalyticsEvent } from 'shared/lib/send-analytics-event';
import { getItemFromStorage, removeItemFromStorage, setItemToStorage } from 'shared/lib/storage';
import { getCookie } from 'cookies-next';
import { useMe } from 'context/user';
import { hashPhoneNumber } from 'shared/lib/hash-phone-number';
import { IUser } from 'common/lib/parse-jwt';
import { CookiesEnum } from 'common/lib/auth';
import { ICarpoolFormParams } from 'common/api/order';
import { ACTIVE_ORDER_STORAGE_KEY } from 'shared/constants/order';
import * as Sentry from '@sentry/nextjs';
import { useAnalytics } from 'shared/hooks/use-analytics';
import { useCallback } from 'react';

export const useRequestCreateOrderMutation = (): UseMutationResult<{ id: number }, AxiosError, IOrderForm, unknown> => {
    const { addSnack } = useSnacks();
    const router = useUTMRouter();
    const { t } = useTranslation();

    return useMutation(
        async (data: IOrderForm) =>
            order.createOrder({
                ...data,
            }),
        {
            onSuccess: async (data) => {
                addSnack(t('order.successful_create_order'), EnumSnackType.success, 3000);
                setItemToStorage('chat_open_status', false);
                await router.push('/orders');
                return data;
            },
            onError: async (error: AxiosError<IErrorResponse>) => {
                if (error?.response?.status === 409) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    addSnack(t('order.order_already_exist'), EnumSnackType.warning, 3000);
                    await router.push('/orders');
                } else {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    addSnack(error?.response?.data?.meta?.message ?? 'error', EnumSnackType.failure);
                }

                return error.response;
            },
        },
    );
};

export const useAcceptBidMutation = () => {
    const { addSnack } = useSnacks();

    return useMutation(async (bidId: string) => order.acceptBid(bidId), {
        onSuccess: async (data) => {
            return data;
        },
        onError: async (error: AxiosError<IErrorResponse>) => {
            if (error.response?.data?.meta?.code === 409) {
                addSnack(error.response?.data?.meta?.message ?? 'error', EnumSnackType.failure);
            }
        },
    });
};
export const useWatchBidMutation = () => {
    return useMutation(async (bidId: string) => order.watchBid(bidId), {
        onSuccess: async (data) => {
            return data;
        },
        onError: async (error: AxiosError<IErrorResponse>) => {
            logger.error({ error });
        },
    });
};

export const useRejectBidMutation = () => {
    const { addSnack } = useSnacks();

    return useMutation(async (bidId: string) => order.rejectBid(bidId), {
        onSuccess: async (data) => {
            return data;
        },
        onError: async (error: AxiosError<IErrorResponse>) => {
            // eslint-disable-next-line no-console
            logger.error({ acceptBidError: error });

            if (error.response?.data?.meta?.code === 409) {
                addSnack(error.response?.data?.meta?.message ?? 'error', EnumSnackType.failure);
            }
        },
    });
};
export const useFinishOrderMutation = () => {
    const { addSnack } = useSnacks();

    return useMutation(async (orderId: string) => order.finishOrder(orderId), {
        onSuccess: async (data) => {
            return data;
        },
        onError: async (error: AxiosError<IErrorResponse>) => {
            logger.error({ acceptBidError: error });
            if (error.response?.data?.meta?.code === 409) {
                addSnack(error.response?.data?.meta?.message ?? 'error', EnumSnackType.failure);
            }
        },
    });
};

export const useCreateOrder = () => {
    const createOrder = useRequestCreateOrderMutation();
    const router = useUTMRouter();
    const { requestUserInfo } = useMe();
    const { sendEventBloomreach } = useAnalytics();

    const getOrdersPassenger = async () => {
        try {
            return await order.getOrder();
        } catch (e) {
            Sentry.captureException(e);

            return null;
        }
    };

    const requestOrder = useCallback(async () => {
        try {
            const referrerPage = getCookie(CookiesEnum.referrerPage);
            if (referrerPage) {
                if (referrerPage === 'rideshare_main') {
                    await router.push('/rideshare/main');
                } else {
                    const cookieParams = getCookie(CookiesEnum.formFilter);
                    if (cookieParams) {
                        const params = JSON.parse(cookieParams as string) as ICarpoolFormParams;

                        await router.push({
                            pathname: '/rideshare/rides',
                            query: {
                                to: params?.city_to_id,
                                from: params?.city_from_id,
                                departure_time: params?.departure_time,
                                seats_count: params?.seats_count,
                            },
                        });
                    }
                }
            } else {
                const orderData: IOrderForm | undefined = getItemFromStorage('active_order');
                const ordersPassenger = await getOrdersPassenger();
                const userInfo = (await requestUserInfo()) as IUser;
                if (orderData) {
                    await createOrder.mutateAsync(orderData);
                    const response = await getOrdersPassenger();
                    if (response?.id) {
                        await sendEventBloomreach('order_created', {
                            // rec_price: values.rec_price !== 0 ? values.rec_price : null,
                            customer_price: response.price,
                            currency: response.currency_code,
                            order_type: response.type,
                            payment_method: response.payment_type.method,
                            payment_method_id: response.payment_type.id,
                            comment: '',
                            destination_address: response.address_to,
                            pickup_address: response.address_from,
                            pickup_city_id: response.city_from.id,
                            destination_city_id: response.city_to.id,
                            order_ts: response.created_at,
                            order_id: response.id,
                        });

                        sendAnalyticsEvent('web.intercity.passenger.bids_feed.view', {
                            order_id: response.id,
                            user_id: response.user_id,
                            order_from: orderData.address_from,
                            order_to: orderData.address_to,
                            order_date: new Date(orderData.departure_time.timestamp * 1000),
                            order_price: orderData.price,
                            order_pass_num: orderData.passengers_count,
                            order_is_pull: orderData.type === 'pool',
                            order_currency: orderData.currency_code,
                            partner_source: orderData.source ?? '',
                            partner_ref_id: orderData.source_ref_id ?? '',
                            new_customer: getCookie('new_customer_status') ?? '',
                            userPhoneNumber: hashPhoneNumber(userInfo.phone),
                        });

                        Sentry.captureMessage(`Order ${response.id} created`, {
                            level: 'info',
                            user: { id: response.user_id },
                        });
                    }
                    removeItemFromStorage(ACTIVE_ORDER_STORAGE_KEY);
                    await router.push('/orders');
                } else if (
                    ordersPassenger?.status?.includes('done') ||
                    ordersPassenger?.status?.includes('cancelled_passenger')
                ) {
                    await router.push('/');
                } else {
                    await router.push('/orders');
                }
            }
        } catch (e) {
            Sentry.captureException(e);
            logger.error(e, 'useCreateOrder');
        }
    }, []);

    return requestOrder;
};
